













import BaseRouter from '@/components/BaseRouter.vue';
import MemberPage from '@/layouts/MemberLayout/MemberPage.vue';
import { defineComponent } from '@/plugins/composition';
import { routeNames } from '@/router/routeNames';

export default defineComponent({
  components: { MemberPage, BaseRouter },
  name: 'ProfilePage',
  setup() {
    return {
      routeNames,
    };
  },
});
